"use strict";
import * as flsFunctions from "./modules/functions.js";
import burger from "./modules/burger.js";
import {scrollToAnchor} from "./modules/scrollToAnchor.js";


flsFunctions.isWebp();
// document.documentElement.style.setProperty('--vh', (window.innerHeight * 0.01) + 'px');
// window.addEventListener('resize', function () {
//     document.documentElement.style.setProperty('--vh', (window.innerHeight * 0.01) + 'px');
// });
// window.addEventListener('scroll', function () {
//     document.documentElement.style.setProperty('--vh', (window.innerHeight * 0.01) + 'px');
// });
window.addEventListener("DOMContentLoaded", () => {
    try {
        burger();
        scrollToAnchor();



    } catch (e) {
        console.log(e);
    }
});


