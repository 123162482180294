import {getElement} from "./helpers.js";

const burger = () => {

    const burger = getElement('.burger');

    burger.addEventListener("click", () => {
        burger.classList.toggle("active");
        getElement('sidebar').querySelector('.menu').classList.toggle('active');
    });

};
export default burger;
