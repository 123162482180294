import {getElement} from "./helpers.js";

const scrollToAnchor = function () {
    const links = document.querySelectorAll(".scroll-to");
    links.forEach((link) => {
        link.addEventListener("click", function (event) {
            event.preventDefault();
            const blockID = link.getAttribute("href");
            // console.log(blockID)
            // console.dir(getElement(`${blockID}`))
            getElement(`${blockID}`).scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
            // window.scrollTo({
            //     top: getElement(`${blockID}`).offsetTop - 20,
            //     behavior: "smooth",
            //     block: 'start'
            // });
        });
    });
};
function scrollTo(element, to, duration) {
    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;

    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}

export {scrollToAnchor};
